@media (max-width: 1199px) {
  /* Styles for large desktops and above */
  .cursos-layout {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .cursos-layout {
    grid-template-columns: 1fr 1fr;
  }
}
