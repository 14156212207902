.phone-number-layout {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.popover-texto {
  text-align: center;
}
.ant-table-wrapper tr.ant-table-expanded-row > td {
  background-color: white !important;
}
.ant-table-wrapper
  .ant-table.ant-table-small
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin-block: -8px;
  margin-inline: 15px -8px;
}
.number-ff {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif, monospace;
  text-align: right;
}

.number-center {
  text-align: center;
}
.span-mid-width {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.botones-wrapper-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mora-curso-name {
  max-width: calc(100% - 25px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 14px;
}

.mora-curso-numbers {
  line-height: 14px;
}

.mora-curso-row {
  font-size: 12px;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
}

.en-mora {
  color: #ff4d48;
  cursor: pointer;
}
