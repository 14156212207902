@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap");

:root {
  color: rgba(240, 240, 240, 1);
  background-color: #252525;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --detail-color: #2dacbd;
  /* --detail-color: #10DD6E; */
  /* --detail-color: #55cc80; */
  --background-color: #252525;
  --complementary-color: rgba(240, 240, 240, 1);
  --hover-color: rgba(240, 240, 240, 0.3);
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

b {
  font-weight: 600;
}

/* button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}  */


::selection {
  background-color: var(--detail-color);
  color: white;
}