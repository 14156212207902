.modal-transferencia-wrapper {
  display: flex;
  flex-direction: column;
  width: clamp(400px, 90vw, 700px);
  gap: 16px;
}

.columna-select {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.concepts-grid {
  display: flex;
  flex-direction: column;
}

.transferencia-conceptos-fila {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.transferencia-columna {
  padding: 0px 8px;
  padding-top: 4px;
  line-height: 32px;
}

.transferencia-columna:nth-child(1) {
  width: 40%;
}

.transferencia-columna:nth-child(2) {
  width: 20%;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.transferencia-columna:nth-child(3) {
  width: 40%;
}

.close-icon {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}