.App {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
}

.span-testing {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-weight: 600;
  font-size: 18px;
  color: green;
}

.fade-in {
  opacity: 0;
  animation: fade-in 400ms forwards;
}

@keyframes fade-in {
  from {
    opacity: 0
    
  }
  to {
    opacity: 1;
  }
}