.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.background-image {
  position: absolute;
  background-color: #252525;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.login-form {
  width: clamp(250px, 400px, 90%);
  /* height: clamp(350px, 400px, 90%); */
  background: rgba(255, 255, 255, 1);
  /* backdrop-filter: blur(15px); */
  z-index: 2;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}
.titulo-login {
  font-size: 36px;
  margin: 24px auto;
  color: var(--background-color);
  margin-bottom: 36px;
  /* color: #f4f4f4; */
}
.btn-generico {
  margin: 12px 0px;
  background-color: var(--background-color);
  color: var(--complementary-color);
  border-color: var(--background-color);
}
.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btn-group a {
  text-decoration: none;
  color: var(--background-color);
  /* color: #f4f4f4; */
}
.btn-group a:hover {
  color: var(--detail-color);
}
