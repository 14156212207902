.student-view-wrapper {
  width: 100%;
  height: 100%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
}
.student-home-wrapper {
  color: #252525;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.student-home-wrapper::-webkit-scrollbar {
  display: none;
}

.header-student {
  padding: 1rem 1rem;
  font-size: 20px;
  display: flex;
  letter-spacing: 2px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  background-color: var(--background-color);
}

.pointer {
  cursor: pointer;
}

.student-menu-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 8px 0px;
  background-color: var(--background-color);
  color: white;
  position: absolute;
  top: 56px;
  left: 0px;
}

.student-menu-item {
  cursor: pointer;
  font-size: 1.125rem;
  padding: 12px;
}

.student-menu-item:hover {
  /* color: #b8b8b8; */
  background-color: #ffffff65;
}

.mobile-section-title {
  width: 100%;
  margin: 1rem;
  font-weight: 600;
  font-size: 1.125rem;
}

.cursos-layout {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 1rem 1rem 1rem;
  gap: 1rem;
  align-content: flex-start;
}

.curso-wrapper {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  height: fit-content;
  cursor: pointer;
}

.curso-title {
  font-weight: 500;
}

.turno-description-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
