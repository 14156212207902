.form-create-wrapper {
  color: var(--background-color);
}
.social-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  cursor: pointer;
  color: var(--background-color);
}
.align-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.btn-cancelar {
  border: 1px solid var(--background-color);
  color: var(--background-color);
}
.btn-guardar {
  background-color: var(--background-color);
  color: var(--complementary-color);
  border: 1px solid var(--background-color);
}
.ant-input-disabled {
  color: #252525 !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #252525 !important;
}
.titulo-curso {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 8px;
}
.horarios-layout {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  margin: 16px 0px;
}
.turno-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: #f4f4f4;
  border-radius: 8px;
}
.linea-turno {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.form-layout {
  height: calc(100%);
  position: relative;
  display: flex;
  flex-direction: column;
}
.form-custom-footer {
  border-top: 1px solid #d4d4d4;
  padding-top: 8px;
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: white;
  margin-bottom: 0px !important;
}
.space-between {
  justify-content: space-between;
}
.tabs-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}
.ant-tabs-content {
  height: 100%;
}
.ant-tabs-tabpane {
  height: 100%;
}
.form-content {
  height: calc(100% - 50px);
  overflow-y: auto;
  -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
}
.form-content::-webkit-scrollbar {
  display: none;
}

.form-content-details {
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
}
.form-content-details::-webkit-scrollbar {
  display: none;
}
.move-student-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #f4f4f4;
  margin-bottom: 8px;
}
.contenedor-cuota {
  padding: 4px 8px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
}

.ant-form-item {
  margin-bottom: 16px;
}
