.main-view-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  background-color: var(--complementary-color);
  color: var(--background-color);
}
.side-menu-wrapper {
  height: 100%;
  width: 70px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 5vh;
  padding-bottom: 5vh;
}
.modulo-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 70px);
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  -webkit-animation: fadein 1s ease-in; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s ease-in; /* Firefox < 16 */
  -ms-animation: fadein 1s ease-in; /* Internet Explorer */
  -o-animation: fadein 1s ease-in; /* Opera < 12.1 */
  animation: fadein 1s ease-in;
}

@keyframes fadein {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.modulo-header {
  padding: 12px;
  background-color: var(--background-color);
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.modulo-header-titulo {
  font-size: 24px;
  color: var(--complementary-color);
}
.modulo-content {
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modulo-content::-webkit-scrollbar {
  display: none;
}
.side-menu-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}
.menu-item {
  display: grid;
  place-items: center;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  color: var(--complementary-color);
}
.selected {
  background-color: var(--complementary-color);
  color: var(--background-color);
}
.menu-item:hover {
  background-color: var(--hover-color);
}
.ant-table-wrapper .ant-table {
  border-radius: 0px;
}
.fila-acciones {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.icono-acciones {
  font-size: 18px;
  cursor: pointer;
}
.ant-drawer-body {
  height: 100% !important;
  overflow: hidden !important;
}
.form-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.form-wrapper::-webkit-scrollbar {
  display: none;
}
.table-wrapper {
  overflow: auto;
  width: clamp(300px, 90%, 100%);
  margin-inline: auto;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* .table-wrapper table {
  font-size: 14px;
} */
.table-wrapper table th {
  height: 45px;
}
.table-wrapper table tbody tr {
  height: 60px;
}
.content-popover {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 230px;
  text-align: center;
  margin-bottom: -8px;
}
.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.between {
  justify-content: space-between;
}

.gap4 {
  gap: 4px !important;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap24 {
  gap: 24px;
}

.dashboard-layout {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.columna-dashboard {
  width: 50%;
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.header-columna {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.chart-container {
  width: 100%;
}
.total-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}
.total-p {
  font-size: 40px;
}
.titulo-sistema {
  font-size: 60px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.subtitulo-sistema {
  font-size: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.modal-wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
}
.modal-content {
  background-color: white;
  width: clamp(300px, 400px, 90%);
  border-radius: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  max-height: 90%;
  box-sizing: border-box;
  position: relative;
}
.logged-in-user-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.logged-in-user-name {
  font-size: 16px;
  font-weight: 500;
}

.icon-action-wrapper {
  height: 18px;
}