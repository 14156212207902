.usuarios-layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin-inline: auto;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
}
.column {
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow: hidden;
  overflow-y: auto;
}
.column:nth-child(1) {
  width: 30%;
  max-width: 250px;
}
.column:nth-child(2) {
  flex: 1 1 70%;
}
.usuario-wrapper {
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  background-color: #dfdfdf;
  color: #252525;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.user-name {
  width: 100%;
  text-overflow: ellipsis;
  font-weight: 600;
}
.rol {
  font-size: 13px;
  margin-left: auto;
  color: #555;
  font-weight: 600;
  text-overflow: ellipsis;
}
.column-usuarios {
  width: 50%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.column-usuarios::-webkit-scrollbar {
  display: none;
}
.users-wrapper {
  width: clamp(200px, 300px, 100%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.users-wrapper::-webkit-scrollbar {
  display: none;
}
.row-roles {
  display: flex;
  flex-direction: column;
  height: 50%;
}
.usuarios-header {
  width: clamp(200px, 300px, 100%) !important;
  justify-content: space-between;
}
.permisos-wrapper {
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 2px 4px;
  border-radius: 4px;
}

.permisos-wrapper:hover {
  background-color: #f4f4f4;
}

.permisos-item {
  width: fit-content;
  font-size: 16px;
  gap: 16px !important;
}

.user-upper-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
}
.columna {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
