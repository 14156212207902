.menu-config {
  max-height: 100%;
  height: fit-content;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 0px 8px;
  background-color: #252525;
  padding-bottom: 8px;
}
.menu-option {
  padding: 8px 12px;
  color: white;
  background-color: #252525;
  cursor: pointer;
}
.option-selected {
  background-color: white;
  color: #252525;
}
.config-content {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 8px 8px;
  padding: 8px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}
.config-layout-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--complementary-color);
}
.config-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 800px;
  height: 100%;
  overflow-y: auto;
  gap: 8px;
}

.tabla-config-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  gap: 8px;
}
.config-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: center;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  padding: 8px;
}
.city-wrapper {
  border-radius: 6px;
  padding: 4px 8px;
  width: fit-content;
  max-width: 100%;
  text-overflow: ellipsis;
}
.city-selected {
  background-color: #252525;
  color: white;
}
.caja-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 8px;
  padding: 4px 8px;
}
.config-table-wrapper {
  display: flex;
  flex-direction: column;
  width: clamp(200px, 300px, 100%);
  height: 100%;
  position: relative;
}
.column-ciudades {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.column-ciudades::-webkit-scrollbar {
  display: none;
}
.config-new-title {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
}
.config-table-wrapper .form-custom-footer {
  border-top: none !important;
}

.custom-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal-wrapper .ant-modal-content {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.custom-modal-wrapper .ant-modal-body {
  width: fit-content;
}

.transferencias-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}